import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["imageSection", "contentSection", "button"]

  connect() {
    // Scroll-triggered animations script
    this.elements = this.element.querySelectorAll("[data-animate]")
    this.observeElements()
  }

  observeElements() {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const element = entry.target
          const animationClass = element.getAttribute("data-animate")

          // Add animation and make element persistently visible
          element.classList.add(animationClass, "animated")
          element.style.opacity = "1" // Ensure visibility after animation

          observer.unobserve(element) // Stop observing after animation
        }
      })
    }, {
      threshold: 0.1 // Trigger when 10% of the element is visible
    })

    this.elements.forEach(el => {
      if (!el.classList.contains("animated")) {
        el.style.opacity = "0" // Start hidden
        observer.observe(el)
      }
    })
  }
}
