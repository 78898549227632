// app/javascript/controllers/quiz_form_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submitButton", "option", "options"];

  connect() {
    this.toggleSubmitButton();
  }

  toggleSubmitButton() {
    const anyOptionChecked = this.optionTargets.some(option => option.checked);
    this.submitButtonTarget.disabled = !anyOptionChecked;
  }

  handleOptionChange() {
    this.toggleSubmitButton();
  }

  toggleOptions(event) {
    const optionsContainer = event.target.nextElementSibling;
    if (optionsContainer.classList.contains('hidden')) {
      optionsContainer.classList.remove('hidden');
      event.target.textContent = 'Hide ';
    } else {
      optionsContainer.classList.add('hidden');
      event.target.textContent = 'Show ';
    }
  }
}
